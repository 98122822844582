.psc-footer {
    width: 100%;
    display: inline-block;
}

.psc-footer .logo-footer img {
    height: 70px;
}


.psc-footer p,
.psc-footer p a {
    color: rgba(var(--grey), 1);
}