.hero-home figure img {
    width: 100%;
    object-fit: cover;
    opacity: 0.7;
    height: 800px;
}

.hero-home figure {
    margin-bottom: 0;
    background: rgba(var(--white), 1);
}

.hero-home .hero-text {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 991px) {
    .hero-home figure img {
        height: 500px;
        opacity: 0.3;
    }
}

.service-slider-container .psc-card {
    width: calc(50% - 30px) !important;
    margin: 15px;
    display: inline-block;
}

.service-slider-container .slick-list,
.service-slider-container .slick-slide {
    height: 100%;
}

.service-slider-container .slick-slide>div {
    height: 100%;
}

@media (max-width: 991px) {
    .service-slider-container .psc-card {
        width: 100% !important;
        margin: 10px 0;
    }

    .service-slider-container .slick-dots {
        bottom: -20px !important;
    }
}
